<template>
  <div class="property-apps">
    <el-card v-for="(module, key) in property_apps" shadow="always" class="app-item"
             :body-style="{ padding: '0' }" :key="key">
      <div slot="header" class="clearfix title">
        <img :src="module.icon" alt="">
        <span style="font-weight: 700">{{ $t(module.module_name) }}</span>
      </div>
      <el-card v-for="module_item, ch_key in module.children"
               style="border-radius: 0" class="card-item"
               :key="ch_key" @click.native="goToModuleItem(module_item)">
        <span class="property-name">{{ $t(module_item.name) }}</span>
      </el-card>
    </el-card>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import {setCookies, checkPermission} from '@/common/utils'
import modules_list from '@/common/modules_list'

export default {
  name: 'Property',
  props: ['property'],
  mounted() {
    this.property_apps = modules_list.property_apps
      .filter(p => this.showModule(p))
      .map(p => ({...p, children: p.children.filter(ch => this.showModuleItem(ch))}))
  },
  data() {
    return {
      property_apps: []
    }
  },
  computed: {
    ...mapState({
      permissions: state => state.current.permissions
    })
  },
  methods: {
    showModule(module) {
      let items = module.children.map(child => {
        return this.showModuleItem(child)
      })

      return items.some(item => item)
    },
    showModuleItem(module_item) {
      let prop_condition = false
      let permission = checkPermission(this.permissions, module_item.permission_object, 'READ_WRITE')
      let propertyType
      if (this.property.property_type === 'HOTEL') {
        propertyType = 'PMS'
      } else {
        propertyType = 'RETAILKEY'
      }

      if (module_item.property_type === 'ALL') {
        prop_condition = true
      } else {
        prop_condition = module_item.property_type.split(',').some(item => item === this.property.property_type)
      }

      if(module_item.permission_object === 'ENABLE_FRONTDESK_NG' 
              && checkPermission(this.permissions, 'ENABLE_FRONTDESK_NG', 'READ_WRITE')){
            module_item.name = 'lbl_frontdesk'
        } else if(module_item.permission_object === 'ENABLE_FRONTDESK' 
              && checkPermission(this.permissions, 'ENABLE_FRONTDESK', 'READ_WRITE')){
          return prop_condition && !checkPermission(this.permissions, 'ENABLE_FRONTDESK_NG', 'READ_WRITE')
        }

      return prop_condition && permission
    },
    navigateToAppURL(queries, module_item) {

      let urlToNavigate = this.property.is_ng_environment ? module_item.ng_url : module_item.url;

      if (queries.length > 0) {
        if (module_item.route_path) {
          window.location.href = `${urlToNavigate}/${module_item.route_path}/?${queries.join('&')}`
        } else {
          window.location.href = `${urlToNavigate}/?${queries.join('&')}`
        }
      } else {
        if (module_item.route_path) {
          window.location.href = `${urlToNavigate}/${module_item.route_path}`
        } else {
          window.location.href = `${urlToNavigate}`
        }
      }
    },
    goToModuleItem(module_item) {
      let queries = []
      let cookie_param = module_item.hasOwnProperty('cookie_param') ? module_item.cookie_param : false
      setCookies(cookie_param)

      if (module_item.hasOwnProperty('params')) {
        let all_params = module_item.params.split(',')

        for (let param in all_params) {
          if (all_params[param] === 'origin') {
            queries.push(`${all_params[param]}=home`)
          }
          if (all_params[param] === 'pc') {
            queries.push(`${all_params[param]}=${this.property.code}`)
          }
          if (all_params[param] === 'property_id') {
            queries.push(`${all_params[param]}=${this.property.id}`)
          }
        }
      }

      if (this.property.is_ng_environment) {


      }

      this.navigateToAppURL(queries, module_item);
    }
  }
}
</script>
<style lang="scss" scoped>
$blue: #46A0FC;

.property-apps {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 100%;
  user-select: none;

  @supports (display: grid) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto auto;
    grid-gap: 30px;
    max-width: 90%;
    margin: 0 auto;
    padding-bottom: 50px;
    min-height: 600px;
  }

  .app-item {
    max-width: 420px;

    .title {
      display: flex;
      align-items: center;

      img {
        height: 20px;
        margin-right: 10px;
      }
    }

    .card-item {
      border-right: none;
      border-left: none;
      border-top: none;

      :hover {
        background-color: whitesmoke;
        color: $blue;
        cursor: pointer;
      }

      .property-name {
        padding-left: 30px;
      }
    }
  }
}

@media (max-width: 768px) and (min-width: 420px) {
  .property-apps {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    @supports (display: grid) {
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 420px) {
  .property-apps {
    flex-direction: column;

    @supports (display: grid) {
      grid-template-columns: 1fr;
    }

    .app-item {
      min-width: 310px;
      margin: 30px auto 0 auto;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .property-apps {
    .app-item {
      width: 420px;
      margin-bottom: 25px;
    }
  }
}
</style>
