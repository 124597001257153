<template>
  <div class="enterprise-apps">
    <el-card shadow="always" class="app-item" :body-style="{ padding: '0' }"
             v-for="(module, key) in enterprise_apps" :key="key">
      <div slot="header" class="clearfix title">
        <img :src="module.icon" alt="">
        <span style="font-weight: 700">{{ $t(module.module_name) }}</span>
      </div>
      <el-card style="border-radius: 0;" class="card-item"
               v-for="(module_item, ch_key) in module.children" :key="ch_key"
               @click.native="goToModule(module_item)">
        <span class="property-name">{{ $t(module_item.name) }}</span>
      </el-card>
    </el-card>
  </div>
</template>
<script>
import {mapState} from 'vuex'
import {checkPermission, setCookies} from '@/common/utils'
import modules_list from '@/common/modules_list'

export default {
  name: 'Enterprise',
  mounted() {
    this.enterprise_apps = modules_list.enterprise_apps
                          .filter(p => this.showModule(p))
                          .map(p => ({...p, children: p.children.filter(ch => this.showModuleItem(ch))}))
  },
  data() {
    return {
      enterprise_apps: []
    }
  },
  computed: {
      ...mapState({
        google_login: state => state.current.google_login,
        sso_login: state => state.current.sso_login,
        enterprise_permissions: state => state.current.enterprise_permissions,
        user: state => state.current.user
      })
    },
    methods: {
      showModule(module) {
        let items = module.children.map(child => {
          return this.showModuleItem(child)
        })

        return items.some(item => item)
      },
      showModuleItem(module_item) {
        if (!module_item.permission_object) {
          return true
        }
        
        // if(module_item.permission_object === 'ENABLE_FRONTDESK_NG' 
        //       && checkPermission(this.enterprise_permissions, 'ENABLE_FRONTDESK_NG', 'READ_WRITE')){
        //     module_item.name = 'lbl_frontdesk'
        // } else if(module_item.permission_object === 'ENABLE_FRONTDESK' 
        //       && checkPermission(this.enterprise_permissions, 'ENABLE_FRONTDESK', 'READ_WRITE')){
        //   return !checkPermission(this.enterprise_permissions, 'ENABLE_FRONTDESK_NG', 'READ_WRITE')
        // }
        return checkPermission(this.enterprise_permissions, module_item.permission_object, 'READ_WRITE');
      },
      goToModule(module_item) {
        setCookies(false)
        if (module_item.callback) {module_item.callback();}
        if (module_item.open_in_new_tab) {
          window.open(module_item.url, "_blank").focus();
        } else {
          window.location.href = module_item.url
        }

      }
    }
  }
</script>
<style lang="scss" scoped>
  $blue: #46A0FC;

  .enterprise-apps {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    overflow-y: auto;
    height: 100%;

    @supports (display: grid) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 30px;
      max-width: 90%;
      margin: 0 auto;
    }

    .app-item {
      /*min-width: 350px;*/
      max-width: 420px;
      user-select: none;

      .title {
        display: flex;
        align-items: center;

        img {
          height: 20px;
          margin-right: 10px;
        }
      }

      .card-item {
        border-right: none;
        border-left: none;
        border-top: none;

        :hover {
          background-color: whitesmoke;
          color: $blue;
          cursor: pointer;
        }

        .property-name {
          padding-left: 30px;
        }
      }
    }
  }

  @media (max-width: 768px) and (min-width: 420px) {
    .enterprise-apps {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      @supports (display: grid) {
        grid-template-columns: 1fr;
      }
    }
  }

  @media (max-width: 420px) {
    .enterprise-apps {
      flex-direction: column;

      @supports (display: grid) {
        grid-template-columns: 1fr;
      }

      .app-item {
        min-width: 310px;
        margin: 30px auto 0 auto;
      }
    }
  }

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .enterprise-apps {
      .app-item {
        width: 420px;
        margin-bottom: 25px;
      }
    }
  }
</style>
